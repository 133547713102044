import { addVerificationToken } from "src/datacraft-util";
import { TeamsSecurityAmender } from "./teams-helper";

export interface ISecurityAmender{
  AppendSecurityToRequest (init:RequestInit): Promise<RequestInit> 
}

var amender:ISecurityAmender  = null;

const inIframe = () => {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}


export const getSecurityAmender = () => {
  if(amender === null)
  {
    // assume in iframe is in teams app
    if(inIframe() || (new URLSearchParams(window.location.search)).has("teams"))
    { 
      console.log("using: TeamsSecurityAmender")
      amender = new TeamsSecurityAmender();
    } else {
      amender = new CookieSecurityAmender();
    }
  }
  return amender;
}


class CookieSecurityAmender implements ISecurityAmender{
  AppendSecurityToRequest(init: RequestInit): Promise<RequestInit> {
    switch (init?.method){
      case "POST":
      case "PUT":
      case "DELETE":
        init = addVerificationToken(init)
      break;
    }
    
    return Promise.resolve(init);
  }
}
