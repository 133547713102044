
export async function fetchJson<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw response;
  }
  if(response.status ==  204)
  {
    throw response;
  }
  return await response.json();
}


export async function fetchBlob(input: RequestInfo, init?: RequestInit): Promise<Blob> {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw response;
  }
  
  return await response.blob();
}

export async function fetchFileBlob(input: RequestInfo, init?: RequestInit): Promise<{ filename:string, blob:Blob}> {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw response;
  }

  var filename:string
  let disposition =response.headers.get('content-disposition')
  var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  var matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) { 
    filename = matches[1].replace(/['"]/g, '');
  }

  return { filename, blob: await response.blob()};
}

// for use when we only care about a successful response
export async function fetchOk(input: RequestInfo, init?: RequestInit): Promise<void> {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw response;
  }
  return;
}


export async function fetchString(input: RequestInfo, init?: RequestInit): Promise<string> {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw response;
  }
  return await response.text();
}

export async function fetchNumber(input: RequestInfo, init?: RequestInit): Promise<number> {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw response;
  }
  return Number(await response.text());
}


export function addVerificationToken(init: RequestInit): RequestInit {
  var token = (document.getElementById("ApiRequestVerificationToken") as HTMLInputElement).value;
  init.headers = { ...init.headers, ...{ 'X-CSRF-TOKEN': token } }
  return init;
}