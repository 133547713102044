export const raiseFetchResponseError = async (error: unknown): Promise<never> =>{ 

if (error instanceof TypeError) {
    console.error("Type Error occurred:", error.message);
} else if (error instanceof SyntaxError) {
    console.error("Syntax Error occurred:", error.message);
} else if (error instanceof Error) {
    console.error("General Error occurred:", error.message);
} else {
    console.error("Unknown error occurred:", error);
}

if (isResponse(error)) {
  if(error.status == 401)
  {
      window.dispatchEvent(new Unauthorized401Event(error))
  }
}

// Rethrow the original error by returning a rejected promise
return Promise.reject(error);

}

function isResponse(obj: any): obj is Response {
    return typeof obj.status === 'number' 
}


export class Unauthorized401Event extends Event {
  static readonly eventName = 'unauthorized401-event';
  onDisconnect?: () => void;

  response:Response;

  constructor(response:Response) {
    super(Unauthorized401Event.eventName, {
      bubbles: true,
      composed: true,
      cancelable: false,
    });
    this.response = response;
  }
}


declare global {
  interface WindowEventMap {
    [Unauthorized401Event.eventName]:Unauthorized401Event
  }
}
