import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js'

import { ThalamusCard } from 'src/components/thalamus-card';
import { AuditableUserResult, AuditableUsersResult, services } from 'src/services';
import { Task, TaskStatus } from '@lit-labs/task';
import { raiseAsyncPromiseEvent } from 'src/modules/helpers/message-helper';

ThalamusCard;
@customElement('colleague-audit')
export class ColleagueAudit extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
      }

      .header{
        font-size: 3.5rem;
          font-weight: 300;
          line-height: 1.2;
          margin-block:0em;
      }
      .lead {
          font-size: 1.25rem;
          font-weight: 300;
      }
      .font-italic {
          font-style: italic;
      }

      hr {
          margin-top: 1rem;
          margin-bottom: 1rem;
          border: 0;
          border-top: 1px solid rgba(0,0,0,.1);
      }

      .user-grid{
        display:grid;
        grid-template-columns: 4fr 1fr 1fr;
        row-gap: 1.2em;
      }

      fluent-button{
        width:min-content;
      }

      ::slotted(*) {
          font-size: 1.25rem;
          font-weight: 300;
      }

    `
  ];

  @property({ type: Number }) practiceId: number;

  @state() connected: boolean;

  @state() users: AuditableUserResult[];

  @state() editMode: boolean = false;

  private _apiTask = new Task(this,
    {
      task: async () => {
        var result = await services.facilities.auditableUsers()
        this.practiceId = result.practiceId;
        this.users = result.users;
        return result;
      },
      autoRun: false
    },
  );

  private auditUserHandler = async (userRow: AuditableUserResult, isCurrentEmployee: boolean) => {
    this.users = this.users.filter(u => u != userRow);
    raiseAsyncPromiseEvent(this, services.users.colleagueAuditUser(userRow.targetUserKey, this.practiceId, isCurrentEmployee), { success:`Colleague audited`, failed:`Colleague could not be audited`}  )
  };

  connectedCallback() {
    super.connectedCallback();
    this.editMode = getComputedStyle(this).getPropertyValue("--dc-edit-mode") === "true";
    if(!this.editMode){
      this._apiTask.run();
    } 
  }

  renderAudit = (input: AuditableUsersResult) => html`
    <thalamus-card>
      <h1 class="header">${input.practiceName}</h1>
      <p class="lead">Are the following people still working at ${input.practiceName}?</p>
      <slot>
        <p class="lead">We will remove former colleagues from Thalamus.</p>
      </slot>
      <p class="lead font-italic">If you are unsure, you don't need to do anything.</p>
      <hr>
      <div class="user-grid">
      ${this.users.map(user => html`
          <div >${user.name}</div>
          <fluent-button appearance="accent" @click=${(e: Event) => this.auditUserHandler(user, true)} >Yes</fluent-button>
          <fluent-button @click=${(e: Event) => this.auditUserHandler(user, false)} >No</fluent-button>
      `)}
      </div>
    </thalamus-card>
  `;

  renderEditMode = () => html`
    <thalamus-card>
        <h1 class="header">Colleague Audit (preview mode)</h1>
        <p class="lead">Facility colleagues will be shown here when published. 
           <li>Logged in user must be part of exactly one facility</li>
           <li>Inactive users will appear more frequently than active users</li>
           <li>Audited users part of multiple facility will removed for the Logged in users facility</li>
           <li>Audited users part of one facility will removed from their facility, any groups and then deactivated</li>
        </p>
    </thalamus-card>
  `


  render() {
    if(this.editMode){
      return this.renderEditMode();
    }
    
    
    return html`
      ${this._apiTask.render({
      pending: () => nothing,
      complete: (input) => this.renderAudit(input),
      error: () => nothing,
      initial: () => nothing,
    })}
    `;
  }
}
