import { app, authentication } from "@microsoft/teams-js";
import type { ISecurityAmender } from "./authentication-helper";

export class TeamsSecurityAmender implements ISecurityAmender {
  private getToken = ():Promise<string> => authentication.getAuthToken();
   AppendSecurityToRequest = async (init:RequestInit): Promise<RequestInit> => {
    await app.initialize();
    const token = await this.getToken();
    init.headers = { ...init.headers, Authorization: `Bearer ${token}` }
    return init;
   }
}