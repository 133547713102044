import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'

declare global {
  interface Window {
    applicationInsightsInstrumentationKey?: string;
    applicationInsightsUserName?: string;
    applicationInsightsApplicationVersion?: string;
  }
}

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: window.applicationInsightsInstrumentationKey
  }
});


appInsights.loadAppInsights();

if (window.applicationInsightsUserName) {
  appInsights.setAuthenticatedUserContext(window.applicationInsightsUserName.replace(/[,;=| ]+/g, "_"));
}


if (window.applicationInsightsApplicationVersion) {
  // the first track needs to be manually set, not sure why
  appInsights.context.application.ver = window.applicationInsightsApplicationVersion;
  var applicationVersionTelemetryInitializer = (envelope: ITelemetryItem) => {
    appInsights.context.application.ver = window.applicationInsightsApplicationVersion;
  };
  appInsights.addTelemetryInitializer(applicationVersionTelemetryInitializer);
}

appInsights.trackPageView();
export default appInsights;
