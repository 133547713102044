
declare global {
  interface ElementEventMap {
    'message-promise': CustomEvent<MessagePromise<any>>;
  }
}


export type MessageDetail = {message:string, level:"error"|"success" }
export type MessagePromise<T> = {task:Promise<T>, messages:{ success:string, failed:string, progress?:string}  }
export type MessageActionPromise<T> = {taskAction:() => Promise<T>, messages:{ success:string, failed:string, progress?:string}  }

export const raiseErrorMessage= (element:HTMLElement, message:string) => {
  element.dispatchEvent(new CustomEvent<MessageDetail>("interface-message",{detail:{message, level:"error"}, bubbles:true, composed:true, cancelable:true}))
}
export const raiseSuccessMessage= (element:HTMLElement, message:string) => {
  element.dispatchEvent(new CustomEvent<MessageDetail>("interface-message",{detail:{message, level:"success"}, bubbles:true, composed:true, cancelable:true}))
}


export const raisePromiseEvent = <T>(element:HTMLElement, input:MessagePromise<T>) => {
  element.dispatchEvent(new CustomEvent<MessagePromise<T>>("message-promise",{detail:input, bubbles:true, composed:true, cancelable:true}))
}

export const raiseAsyncPromiseEvent = async <T>(element:HTMLElement, promise: Promise<T>, messages:{ success:string, failed:string, progress?:string}   ) => {
  element.dispatchEvent(new CustomEvent<MessagePromise<T>>("message-promise",{detail:{ task:promise, messages}, bubbles:true, composed:true, cancelable:true}))
  await promise;
}

export const raiseMessageAction = async <T>(element:HTMLElement, promiseAction:  ()=> Promise<T>, messages:{ success:string, failed:string, progress?:string}   ) => {
  var promise = promiseAction();
  var event  = new MessagePromiseEvent<T>(promise, messages)
  element.dispatchEvent(event);
  return await promise;
}

export class MessagePromiseEvent<T> extends Event {
  static readonly eventName = 'message-promise-2';
  onDisconnect?: () => void;

  readonly promise: Promise<T>;
  messages:{ success:string, failed:string, progress?:string} 
  rejector:(reason?: any) => void
  constructor(promise: Promise<T>,messages:{ success:string, failed:string, progress?:string}) {
    super(MessagePromiseEvent.eventName, {
      bubbles: true,
      composed: true,
      cancelable: false,
    });

    this.promise = promise;
    this.messages = messages;
  }
}

declare global {
  interface HTMLElementEventMap {
    [MessagePromiseEvent.eventName]: MessagePromiseEvent<any>;
  }
}